import { riskScoreList, riskScoreList2, riskScoreTextList, riskKindList, riskKindTextList } from "@/assets/js/riskScoreList";
import analyticsState from "@/store/modules/analytics/state"
import { stableCoinsETH, stableCoinsTRX } from '@/assets/static/stable-coins'
import { ethIcon } from "@/assets/static/eth-base-64-icon";
import { tronIcon } from "@/assets/static/tron-base-64-icon";
import { bnbIcon } from "@/assets/static/bnb-base-64-icon";

export function getNativeCoinIcon(coin) {
    const coinIconMap = {
        'eth': ethIcon,
        'trx': tronIcon,
        'bnb': bnbIcon,
    }

    return coinIconMap[coin] || null
}

export const tokensSorting = (data) => {
    const stableCoinsMap = {
        'ETH': stableCoinsETH,
        'BNB': stableCoinsETH,
        'TRX': stableCoinsTRX,
    }

    const stableCoins = stableCoinsMap[analyticsState.coinData.key.toUpperCase()]

    const nativeIndex = data.findIndex(t => !t.address)

    if (nativeIndex !== -1) {
        data[nativeIndex].icon = getNativeCoinIcon(analyticsState.coinData.key)
    }

    const sortedData = data.reduce((acc, token) => {
        // eslint-disable-next-line no-prototype-builtins
        const isStableCoin = stableCoins.hasOwnProperty(token.address);

        if (isStableCoin || !acc.some(item => item.address === token.address)) {
            acc.push(token);
        }

        return acc;
    }, []).sort((a, b) => {

        if (!a.address && b.address) return -1;
        if (a.address && !b.address) return 1;

        // eslint-disable-next-line no-prototype-builtins
        const aIsStableCoin = stableCoins.hasOwnProperty(a.address);
        // eslint-disable-next-line no-prototype-builtins
        const bIsStableCoin = stableCoins.hasOwnProperty(b.address);

        const aIsMonitored = !!a.isMonitored;
        const bIsMonitored = !!b.isMonitored;

        if (aIsMonitored || bIsMonitored) {
            if (aIsMonitored && !bIsMonitored) {
                return -1; // Monitored have higher priority
            } else if (!aIsMonitored && bIsMonitored) {
                return 1; // Monitored have higher priority
            } else if (aIsMonitored === bIsMonitored) {
                if (b.score === a.score) {
                   return sortByValue(a, b, 'icon')
                }
                return b.score - a.score;
            }
        } else if (aIsStableCoin && bIsStableCoin) {
            // If both a and b are stable coins, sort by the order in stableCoins
            const aIndex = Object.keys(stableCoins).indexOf(a.address);
            const bIndex = Object.keys(stableCoins).indexOf(b.address);
            return aIndex - bIndex;
        } else if (aIsStableCoin && !bIsStableCoin) {
            return -1; // Stable coins have higher priority
        } else if (!aIsStableCoin && bIsStableCoin) {
            return 1; // Stable coins have higher priority
        } else {
            return sortByValue(a, b, 'icon')
        }
    });

    return Array.isArray(sortedData) ? sortedData : [];
}

export const sortByValue = (a, b, sortValue) => {
    const aHasSortValue = !!a[sortValue];
    const bHasSortValue = !!b[sortValue];

    if (aHasSortValue && !bHasSortValue) {
        return -1;
    } else if (!aHasSortValue && bHasSortValue) {
        return 1;
    } else if (aHasSortValue === bHasSortValue) {
        return a.symbol.toLowerCase().localeCompare(b.symbol.toLowerCase());
    }
}

export const hex2rgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
}

export const isValidEthAddress = (address) => {
    return /^0x[0-9a-fA-F]{40}$/.test(address);
}

export const isValidEthHash = (hash) => {
    return /^0x[0-9a-fA-F]{64}$/.test(hash);
}

export const findColorByTypeScore = (val) => {
    return findByTypeScore(val, riskScoreList, riskKindList)
}
export const findColorByTypeScore2 = (val) => {
    return findByTypeScore(val, riskScoreList2, riskKindList)
}
export const findTextColorByTypeScore = (val) => {
    return findByTypeScore(val, riskScoreTextList, riskKindTextList)
}

export const findByTypeScore = (val, list, kindList) => {
    if (val && val == -2) return kindList[0];
    if (val && val == -1) return kindList[1];

    if (val !== 0 && (!val || val < 0)) {
        return list[0];
    } else if (val >= 0 && val <= 10) {
        return list[1];
    } else if (val > 10 && val <= 20) {
        return list[2];
    } else if (val > 20 && val <= 30) {
        return list[3];
    } else if (val > 30 && val <= 40) {
        return list[4];
    } else if (val > 40 && val <= 50) {
        return list[5];
    } else if (val > 50 && val <= 60) {
        return list[6];
    } else if (val > 60 && val <= 70) {
        return list[7];
    } else if (val > 70 && val <= 80) {
        return list[8];
    } else if (val > 80 && val <= 90) {
        return list[9];
    } else if (val > 90) {
        return list[10];
    } else {
        return list[0];
    }
};

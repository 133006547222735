export const stableCoinsETH = {
    "0xdac17f958d2ee523a2206206994597c13d831ec7": "USDT",
    "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48": "USDC",
    "0x6b175474e89094c44da98b954eedeac495271d0f": "DAI",
    "0x0000000000085d4780B73119b644AE5ecd22b376": "TUSD",
    "0x4fabb145d64652a948d72533023f6e7a623c7c53": "BUSD",
    "0x0C10bF8FcB7Bf5412187A595ab97a3609160b5c6": "USDD",
    "0x8e870d67f660d95d5be530380d0ec0bd388289e1": "USDP",
    "0x056fd409e1d7a124bd7017459dfea2f387b6d5cd": "GUSD",
    "0xa47c8bf37f92abed4a126bda807a7b7498661acd": "USTC"
}

export const stableCoinsTRX = {
    "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t": "USDT",
    "TEkxiTehnzSmSe2XqrBj4w32RUN966rdz8": "USDC",
    "TUpMhErZL2fhh4sVNULAbNKLokS4GjC1F4": "TUSD",
    "TMz2SWatiAtZVVcH2ebpsbVtYwUPT9EdjH": "BUSD",
    "TPYmHEhy5n8TCEfYGqW2rPxsghSfzghPDn": "USDD",
    "TMwFHYXLJaRUPeW6421aqXL4ZEzPRFGkGT": "USDJ",
}